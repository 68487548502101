export default [
  {
    text: "Выбор номинации",
    link: { name: "AchievementYearCollectiveNomination" },
    key: "nominations",
    disabled: false,
  },
  {
    text: "Данные",
    link: { name: "AchievementYearCollectiveData" },
    key: "data",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "AchievementYearCollectivePerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Портфолио",
    link: { name: "AchievementYearCollectivePortfolio" },
    key: "portfolio",
    disabled: false,
  },
];
